.rotate:hover {
    animation-duration: 1.5s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes rotate {
    from {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(360deg) scale(1.5);
    }
    to {
        transform: rotate(0deg) scale(1)
    }
}

.scale:hover {
    animation-duration: 1.5s;
    animation-name: scale;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes scale {
    from {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    to {
        transform: scale(1)
    }
}

.fav-heart:hover {
    animation-duration: 0.5s;
    animation-name: fav-heart;
    animation-iteration-count: initial;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes fav-heart {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.3)
    }
}


/* retire les flèches des input type number */
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}